import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { HomePage } from './pages/Home';
import { TechnologyDispensaryPage } from './pages/TechnologyDispensary';
import { DistributionPage } from './pages/Distribution';
import { ManufacturingPage } from './pages/Manufacturing';
import { TechnologyBrandsPage } from './pages/TechnologyBrands';
import { ContactUsPage } from './pages/ContactUs';
import { RegisterPage } from './pages/Register';
import { OklahomaPage } from './pages/Oklahoma';
import { MissouriPage } from './pages/Missouri';
import { NewJerseyPage } from './pages/NewJersey';
import { MontanaPage } from './pages/Montana';
import { MississippiPage } from './pages/Mississippi';
import {NewMexicoPage } from './pages/NewMexico';
import {ColoradoPage } from './pages/Colorado';
import {PrivacyPage } from './pages/Privacy';
import {DisclaimerPage } from './pages/Disclaimer';
import {DoNotSellMyInfoPage } from './pages/DoNotSellMyInfo';
import {SiteMapPage } from './pages/SiteMap';
import {AgeGatePage } from './pages/AgeGate';
import {AboutPage } from './pages/About';

const App = () => (
  
  <BrowserRouter>
   
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/technology-suite-dispensary" exact component={TechnologyDispensaryPage} />
      <Route path="/technology-suite-brands" exact component={TechnologyBrandsPage} />
      <Route path="/distribution" exact component={DistributionPage} />
      <Route path="/manufacturing" exact component={ManufacturingPage} />
      <Route path="/contact-us" exact component={ContactUsPage} />
      <Route path="/oklahoma" exact component={OklahomaPage} />
      <Route path="/missouri" exact component={MissouriPage} />
      <Route path="/mississippi" exact component={MississippiPage} />
      <Route path="/new-mexico" exact component={NewMexicoPage} />
      <Route path="/montana" exact component={MontanaPage} />
      <Route path="/new-jersey" exact component={NewJerseyPage} />
      <Route path="/colorado" exact component={ColoradoPage} />
      <Route path="/register" exact component={RegisterPage} />
      <Route path="/privacy-policy" exact component={PrivacyPage} />
      <Route path="/disclaimer" exact component={DisclaimerPage} />
      <Route path="/do-not-sell-my-info" exact component={DoNotSellMyInfoPage} />
      <Route path="/site-map" exact component={SiteMapPage} />
      <Route path="/age-gate" exact component={AgeGatePage} />
      <Route path="/about" exact component={AboutPage} />
      
    </Switch>
    
  </BrowserRouter>
);

export default App;