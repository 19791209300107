import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import USAMap from "react-usa-map";
import statesJson from "./states.json";
import './style.css';

const Map = () => {
  let history = useHistory();

  const statesCustomConfig = () => {
        return {
          "OK": {
            fill: "#3d9b35",
            clickHandler: (event) => handleActiveStateClick(event.target.dataset.name)
          },
          "MO": {
            fill: "#3d9b35",
            clickHandler: (event) => handleActiveStateClick(event.target.dataset.name)
          },
          "NM": {
            fill: "#3d9b35",
            clickHandler: (event) => handleActiveStateClick(event.target.dataset.name)
          },
          "MS":{
            fill:"#3d9b35",
            clickHandler: (event) => handleActiveStateClick(event.target.dataset.name)
          },
          
          "CO":{
            fill:"#b6d68e",
            clickHandler: (event) => handleComingSoonStateClick('2024-2025 Expansion', event.target.dataset.name)
          },
          "NJ":{
            fill: "#3d9b35",
            clickHandler: (event) => handleActiveStateClick(event.target.dataset.name)
          },
          "OH":{
            fill: "#b6d68e",
            clickHandler: (event) => handleComingSoonStateClick('2024-2025 Expansion', event.target.dataset.name)
          },
          

        };
  };

  
  const MySwal = withReactContent(Swal)
  
  function handleComingSoonStateClick(message, state){
    var title = document.querySelector('[data-name="'+state+'"] title').textContent;
    var htmlTitle = '<h2 className="">'+message+'</h2>';
    const html = '<h1 class="color-primary swal2-title pt-0">'+title+'</h1>'
    MySwal.fire({
      title: htmlTitle,
      html: html,
      //timer: 2500,
      showConfirmButton: false,
    })
  }
  function handleActiveStateClick(state){
    console.log(state);
   
    var titleElement = document.querySelector('[data-name="'+state+'"] title');
    var stateLink = titleElement.textContent.replace(/ /g,"-").toLowerCase();
    //alert(stateLink);
// get the text of the title by:
    history.push(stateLink)
   
  };

    useEffect(() => {
     
    }, []);
    return (
      <>
        <div id="7acf1cb3-5acc-4c75-a08c-fe510977f1d2" className="containerv2 containerv2--national-locations containerv2--w-full containerv2--h-middle">
            <div className="containerv2__regions" data-container-regions>
                <div className="containerv2__region containerv2__region--12" data-region>

                    <div id="SH603dfb78-b12e-4bd3-baaf-3b9c9fc8d6d5" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <h1>National Locations</h1>
                        </div>
                    </div>

                    <div id="b49932c3-d675-4fc0-899b-aa54b658f56a" className="usmap">

                        <a href="#skip_b49932c3-d675-4fc0-899b-aa54b658f56a" className="skip-links__link">Skip interactive
                            map</a>
                        <div className="usmap__area-container">
                            <div className="usmap__area">
                               
                                <USAMap customize={statesCustomConfig()} defaultFill="#595959" />
                              
                            </div>
                        </div>


                    </div>
                    <div id="819004f6-d64b-43e4-b85d-1eaa14267dad" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <p className="map_key map_key1"><strong>ACTIVE</strong></p>
                            <p className="map_key map_key2" style={{"display":"none"}}><strong>COMING SOON</strong></p>
                            <p className="map_key map_key3"><strong>2025 EXPANSION</strong></p>
                        </div>
                    </div>
                    
                </div>

            </div>
            
        </div>
      </>
    )
}
 
export default Map
